import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Card } from "clutch/src/Card/Card.js";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import { ItemIcon } from "@/game-destiny2/components/ItemIcon.jsx";
import { ACTIVITY_MODE } from "@/game-destiny2/constants/activity-modes.mjs";
import { CLASS_BY_BE_ENUM } from "@/game-destiny2/constants/class.mjs";
import { RACE } from "@/game-destiny2/constants/race.mjs";
import { STATS } from "@/game-destiny2/constants/stats.mjs";
import type { Profile } from "@/game-destiny2/models/destiny2-bungie-profile.mjs";
import { getStats } from "@/game-destiny2/utils/loadout-utils.mjs";
import type {
  CharacterStatsAgg,
  MapStatsDisplay,
  MatchAggStats,
  WeaponStatsDisplay,
} from "@/game-destiny2/utils/use-aggregate-stats.mjs";
import PowerIcon from "@/inline-assets/destiny2-power-icon.svg";
import { SquareAvatar } from "@/shared/Avatar.jsx";
import { DataTableTrunc } from "@/shared/DataTable";
import HeadShotStats from "@/shared-fps/HeadShotStats.jsx";
import { displayRate, sanitizeNumber } from "@/util/helpers.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Styled = {
  cardCss: () => css`
    --name-m-w: 20ch;

    @container profile_container (inline-size < 1025px) {
      --name-m-w: 50ch;
    }

    .class-icon {
      border-radius: var(--br);
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      color: var(--shade10-75);
      background-color: var(--class-bg);
    }

    .card-title {
      padding: var(--sp-2) var(--sp-6);
    }

    .agg-name {
      max-width: var(--name-m-w);
      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .avatar-title {
        max-width: var(--name-m-w);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  `,
};

const MAX_SIDEBAR_ROWS = 5;

const Headshots = ({ matchStats }: { matchStats: MatchAggStats }) => {
  if (!matchStats) return <Card loading style={{ height: "450px" }} />;

  return (
    <Card padding="0" className={Styled.cardCss()}>
      <HeadShotStats
        hiddenLegshots
        hideHits
        lastNStats={{
          headshots: matchStats.precisionKills,
          bodyshots: matchStats.kills,
          legshots: 0,
          chestshots: 0,
          stomachshots: 0,
        }}
        matches={matchStats.matches}
      />
    </Card>
  );
};

const Guardians = ({
  profile,
  guardianStats,
}: {
  profile: Profile;
  guardianStats: Record<string, CharacterStatsAgg>;
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    destiny2: { characterLoadout: characterLoadoutMap },
  } = useSnapshot(readState);
  const guardians = profile?.guardians;
  const characterLoadout = characterLoadoutMap[profile?.bungieId] ?? {};
  const characters = characterLoadout.characters;

  const cols = [
    {
      display: t("lol:role", "Role"),
      align: "left",
    },
    characters && { display: t("destiny2:power", "Power"), align: "right" },
    guardianStats && {
      display: t("common:kills", "Kills"),
      isStat: true,
      align: "right",
    },
  ].filter(Boolean);

  const rows = guardians?.map(({ characterId, class: beClass }) => {
    const stats = guardianStats?.[characterId];
    const character = characters?.[characterId];
    const equipmentStats = getStats(character?.equipment);
    const light = equipmentStats[STATS.light];
    const cClass = CLASS_BY_BE_ENUM[beClass];
    const Icon = cClass.icon;
    return [
      {
        display: (
          <SquareAvatar
            avatar={
              character ? (
                `${appURLs.BUNGIE}${character.emblemPath}`
              ) : (
                <div
                  className="class-icon"
                  style={{ "--class-bg": cClass.color }}
                >
                  <Icon />
                </div>
              )
            }
            title={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span className="type-callout--bold shade0">
                  {t(...cClass.label)}
                </span>
                {character && (
                  <span className="type-caption--semi shade1">
                    {t(...RACE[character.raceHash].label)}
                  </span>
                )}
              </div>
            }
          />
        ),
        value: beClass,
      },
      characters && {
        display: (
          <span className="flex align-center gap-1 d-color type-body2-form--bold">
            <span className="power-icon">
              <PowerIcon />
            </span>
            {light?.toLocaleString(language) ?? "----"}
          </span>
        ),
        value: light,
      },
      guardianStats && {
        display: stats.kills.toLocaleString(language),
        value: stats.kills,
        align: "center",
      },
    ].filter(Boolean);
  });

  return (
    <Card padding="0" className={Styled.cardCss()}>
      <p className="type-title--bold card-title">
        {t("destiny2:navigation.guardians", "Guardians")}
      </p>
      <DataTableTrunc
        maxRows={3}
        borderless
        tallRows
        noResultsTitle={["destiny2:suggestions.noGuardians", "No Guardians"]}
        cols={cols}
        rows={rows}
      />
    </Card>
  );
};

const Weapons = ({ weaponStats }: { weaponStats: WeaponStatsDisplay[] }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    destiny2: {
      wiki: { weapons },
    },
  } = useSnapshot(readState);

  const cols = [
    {
      display: t("common:weapons", "Weapons"),
      align: "left",
    },
    { display: t("common:kills", "Kills"), isStat: true, align: "right" },
    {
      display: t("common:stats.hsPercent", "HS%"),
      isStat: true,
      align: "right",
    },
  ];
  const rows = weaponStats?.map(
    ({ weaponReferenceId, kills, precisionKills }) => {
      const weapon = weapons[weaponReferenceId];
      return [
        {
          display: <ItemIcon item={weapon} width={32} />,
          value: weapon?.label,
          className: "agg-name",
        },
        { display: kills.toLocaleString(language), value: kills },
        {
          display: displayRate(precisionKills, kills, 0, 1),
          value: sanitizeNumber(precisionKills / kills),
        },
      ];
    },
  );

  return (
    <Card padding="0" className={Styled.cardCss()}>
      <p className="type-title--bold card-title">
        {t("common:navigation.weapons", "Weapons")}
      </p>
      <DataTableTrunc
        maxRows={MAX_SIDEBAR_ROWS}
        borderless
        cols={cols}
        rows={rows}
        sortCol={1}
      />
    </Card>
  );
};

const Maps = ({ mapStats }: { mapStats: MapStatsDisplay[] }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    destiny2: {
      wiki: { activities },
    },
  } = useSnapshot(readState);

  const cols = [
    {
      display: t("common:location", "Location"),
      // primary: true,
      align: "left",
    },
    { display: t("common:kills", "Kills"), isStat: true, align: "right" },
    {
      display: t("common:stats.hsPercent", "HS%"),
      isStat: true,
      align: "right",
    },
  ];

  const rows = mapStats?.map(
    ({ label, activityReferenceId, kills, precisionKills }) => {
      const { map, difficulty, activityModeEnum } =
        activities[activityReferenceId];
      const mode = ACTIVITY_MODE[activityModeEnum];
      return [
        {
          display: (
            <span className="type-callout--bold shade0">{t(...label)}</span>
          ),
          value: `${mode ? `${t(...mode.label)}-` : ""}${map}${difficulty ? `-${difficulty}` : ""}`,
          className: "agg-name",
        },
        { display: kills.toLocaleString(language), value: kills },
        {
          display: displayRate(precisionKills, kills, 0, 1),
          value: sanitizeNumber(precisionKills / kills),
        },
      ];
    },
  );

  return (
    <Card padding="0" className={Styled.cardCss()}>
      <p className="type-title--bold card-title">{t("common:maps", "Maps")}</p>
      <DataTableTrunc
        maxRows={MAX_SIDEBAR_ROWS}
        borderless
        cols={cols}
        rows={rows}
        sortCol={1}
        noResultsTitle={["destiny2:suggestions.noMapStats", "No map stats"]}
        noResultsDescription={[
          "destiny2:suggestions.noMapStatsDescription",
          "We currently do no have any map stats for you.",
        ]}
      />
    </Card>
  );
};

export default function Sidebar({
  profile,
  matchStats,
  mapStats,
  weaponStats,
  guardianStats,
}: {
  profile: Profile;
  matchStats: MatchAggStats;
  mapStats: MapStatsDisplay[];
  weaponStats: WeaponStatsDisplay[];
  guardianStats: Record<string, CharacterStatsAgg>;
}) {
  return (
    <>
      <Headshots matchStats={matchStats} />
      <Guardians profile={profile} guardianStats={guardianStats} />
      {(!profile || profile.blitzUser) && <Weapons weaponStats={weaponStats} />}
      <Maps mapStats={mapStats} />
    </>
  );
}
