import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import appRefs from "@/app/refs.mjs";
import { ItemIcon } from "@/game-destiny2/components/ItemIcon.jsx";
import { TopStatCardsFallback } from "@/game-destiny2/components/Profile/TopStatCards.jsx";
import type { WeaponStatsDisplay } from "@/game-destiny2/utils/use-aggregate-stats.mjs";
import DataTable from "@/shared/DataTable.jsx";
import { SORT_DESC } from "@/shared/InfiniteTable.jsx";
import { displayRate, sanitizeNumber } from "@/util/helpers.mjs";
import { formatToFixedNumber } from "@/util/i18n-helper.mjs";
import { lazyComponent } from "@/util/lazy-component.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const WeaponTopStatCards = lazyComponent(
  () => import("@/game-destiny2/components/Profile/WeaponTopStatCards.jsx"),
);

export default function Main({
  weaponStats,
}: {
  weaponStats: WeaponStatsDisplay[];
}) {
  const { t } = useTranslation();
  const {
    parameters: [bungieId],
  } = useRoute();
  const {
    destiny2: { profiles },
  } = useSnapshot(readState);
  const { SignIn } = useSnapshot(appRefs);
  const profile = profiles[bungieId];
  if (!profile || profile.blitzUser)
    return <Weapons weaponStats={weaponStats} />;
  return (
    <Card className="flex column text-center" padding="var(--sp-10)">
      <div>
        <div className="type-h6">
          {t("csgo:suggestions.noWeaponKills", "No weapon kills")}
        </div>
        <div className="type-body1-form">
          {t(
            "destiny:suggestions.createBlitzAccountWeaponStats",
            "You must be a Blitz user to receive Destiny 2 weapon stats",
          )}
        </div>
      </div>
      <div>
        <SignIn />
      </div>
    </Card>
  );
}

function Weapons({ weaponStats }: { weaponStats: WeaponStatsDisplay[] }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    destiny2: {
      wiki: { weapons },
    },
  } = useSnapshot(readState);

  const cols = [
    {
      display: t("common:weapon", "Weapon"),
      align: "left",
      isStat: false,
    },
    {
      display: t("common:kills", "Kills"),
      align: "right",
      isStat: true,
    },
    {
      display: t("common:stats.hs", "HS"),
      align: "right",
      isStat: true,
    },
    {
      display: t("val:avgKills", "Avg. Kills"),
      align: "right",
      isStat: true,
    },
    {
      display: t("val:profile.avgHS", "Avg. HS"),
      align: "right",
      isStat: true,
    },
    {
      display: t("common:stats.hsPercent", "HS%"),
      align: "right",
      isStat: true,
    },
    {
      display: t("common:pickRate", "Pick Rate"),
      align: "right",
      isStat: true,
    },
  ];
  const rows = useMemo(
    () =>
      weaponStats?.map(({ weaponReferenceId, ...stats }) => {
        const weapon = weapons[weaponReferenceId];
        const precisionKillAvg = sanitizeNumber(
          stats.precisionKills / stats.count,
        );
        const killAvg = sanitizeNumber(stats.kills / stats.count);
        return [
          {
            display: <ItemIcon item={weapon} width={32} />,
            value: weapon?.label,
          },
          {
            display: formatToFixedNumber(language, stats.kills),
            value: stats.kills,
          },
          {
            display: formatToFixedNumber(language, stats.precisionKills),
            value: stats.precisionKills,
          },
          {
            display: formatToFixedNumber(language, killAvg),
            value: killAvg,
          },
          {
            display: formatToFixedNumber(language, precisionKillAvg),
            value: precisionKillAvg,
          },
          {
            display: displayRate(precisionKillAvg, killAvg, 0, 1),
            value: sanitizeNumber(precisionKillAvg / killAvg),
          },
          {
            display: displayRate(stats.count, stats.count, 0, 1),
            value: sanitizeNumber(stats.count / stats.count),
          },
        ];
      }),
    [language, weaponStats, weapons],
  );

  return (
    <div className="flex gap-4 column">
      <WeaponTopStatCards
        fallback={<TopStatCardsFallback />}
        props={{
          weaponStats,
          weapons,
        }}
      />
      <DataTable
        cols={cols}
        rows={rows}
        indexCol
        noResultsTitle={["csgo:suggestions.noWeaponKills", "No weapon kills"]}
        noResultsDescription={[
          "csgo:suggestions.noWeaponKillsDescription",
          "We currently do no have any weapon kills for you.",
        ]}
        sortDir={SORT_DESC}
        sortCol={1}
      />
    </div>
  );
}
