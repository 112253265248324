import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { formatDuration } from "@/app/util.mjs";
import { TopStatCardsFallback } from "@/game-destiny2/components/Profile/TopStatCards.jsx";
import { ACTIVITY_MODE } from "@/game-destiny2/constants/activity-modes.mjs";
import type { Profile } from "@/game-destiny2/models/destiny2-bungie-profile.mjs";
import type { MapStatsDisplay } from "@/game-destiny2/utils/use-aggregate-stats.mjs";
import DataTable from "@/shared/DataTable.jsx";
import { SORT_DESC } from "@/shared/InfiniteTable.jsx";
import { displayRate, sanitizeNumber } from "@/util/helpers.mjs";
import { formatToFixedNumber } from "@/util/i18n-helper.mjs";
import { lazyComponent } from "@/util/lazy-component.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const MapsTopStatCards = lazyComponent(
  () => import("@/game-destiny2/components/Profile/MapsTopStatCards.jsx"),
);

const ONE_HOUR = 1000 * 60 * 60;

export default function Maps({
  profile,
  mapStats,
}: {
  profile: Profile;
  mapStats: MapStatsDisplay[];
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    destiny2: {
      wiki: { activities },
    },
  } = useSnapshot(readState);

  const cols = [
    {
      display: t("common:map", "Map"),
      align: "left",
      isStat: false,
    },
    {
      display: t("common:wins", "Wins"),
      align: "right",
      isStat: true,
    },
    {
      display: t("common:kills", "Kills"),
      align: "right",
      isStat: true,
    },
    {
      display: t("common:stats.hs", "HS"),
      align: "right",
      isStat: true,
    },
    {
      display: t("common:stats.hsPercent", "HS%"),
      align: "right",
      isStat: true,
    },
    {
      display: t("common:stats.assists", "Assists"),
      align: "right",
      isStat: true,
    },
    {
      display: t("common:stats.deaths", "Deaths"),
      align: "right",
      isStat: true,
    },
    {
      display: t("lol:timePlayed", "Time Played"),
      align: "right",
      isStat: true,
    },
  ];

  const rows = useMemo(
    () =>
      mapStats?.map(({ activityReferenceId, label, ...stats }) => {
        const { map, difficulty, activityModeEnum } = activities[
          activityReferenceId
        ] ?? { map: t("destiny2:unknownActivity", "Unknown Activity") };
        const mode = ACTIVITY_MODE[activityModeEnum];
        const timePlayed = sanitizeNumber(stats.secondsPlayed * 1000);
        return [
          {
            display: t(...label),
            value: `${mode ? `${t(...mode.label)}-` : ""}${map}${difficulty ? `-${difficulty}` : ""}`,
          },
          {
            display: formatToFixedNumber(language, stats.wins),
            value: stats.wins,
          },
          {
            display: formatToFixedNumber(language, stats.kills),
            value: stats.kills,
          },
          {
            display: formatToFixedNumber(language, stats.precisionKills),
            value: stats.precisionKills,
          },
          {
            display: displayRate(stats.precisionKills, stats.kills),
            value: sanitizeNumber(stats.precisionKills / stats.kills),
          },
          {
            display: formatToFixedNumber(language, stats.assists),
            value: stats.assists,
          },
          {
            display: formatToFixedNumber(language, stats.deaths),
            value: stats.deaths,
          },
          {
            display:
              timePlayed > ONE_HOUR
                ? t("val:stats.hoursPlayed", "{{ hours }} hours", {
                    hours: formatDuration(timePlayed, "h:mm:ss"),
                  })
                : t("common:minutes", "{{minutes}} mins", {
                    minutes: formatDuration(timePlayed, "m:ss"),
                  }),
            value: stats.secondsPlayed,
          },
        ];
      }),
    [mapStats, activities, t, language],
  );

  return (
    <div className="flex gap-4 column">
      <MapsTopStatCards
        fallback={<TopStatCardsFallback />}
        props={{
          mapStats,
          profileImgUri: profile?.iconPath,
          activities,
        }}
      />
      <DataTable
        cols={cols}
        rows={rows}
        indexCol
        noResultsTitle={["destiny2:suggestions.noMapStats", "No map stats"]}
        noResultsDescription={[
          "destiny2:suggestions.noMapStatsDescription",
          "We currently do no have any map stats for you.",
        ]}
        sortDir={SORT_DESC}
        sortCol={1}
      />
    </div>
  );
}

export function meta() {
  return {
    title: [`destiny2:meta.profile.title`, `Destiny Performance Overview`],
    description: [
      `destiny2:meta.profile.description`,
      `View your Destiny 2 profile and see how you perform.`,
    ],
  };
}
