import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import MatchListRow from "@/game-destiny2/components/MatchListRow.jsx";
import Guardians from "@/game-destiny2/components/Profile/Guardians.jsx";
import Maps from "@/game-destiny2/components/Profile/Maps.jsx";
import MatchHistoryHeader from "@/game-destiny2/components/Profile/MatchHistoryHeader.jsx";
import { ProfileFilters } from "@/game-destiny2/components/Profile/ProfileFilters.jsx";
import Sidebar from "@/game-destiny2/components/Profile/Sidebar.jsx";
import Weapons from "@/game-destiny2/components/Profile/Weapons.jsx";
import {
  useCharacterAggregates,
  useMapAggregates,
  useMatchAggregates,
  useWeaponAggregates,
} from "@/game-destiny2/utils/use-aggregate-stats.mjs";
import { useMatchListFilter } from "@/game-destiny2/utils/use-match-list-filter.mjs";
import PageHeader from "@/shared/PageHeader";
import SharedProfile, { MatchList, MatchTile } from "@/shared/Profile.jsx";
import { ProfileColumn, ProfileMatchlist } from "@/shared/Profile.style.jsx";
import { filterErrState } from "@/util/eval-state.mjs";
import { useQuery, useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ProfileContainer = styled("div")`
  container-type: inline-size;
  container-name: profile_container;
  width: 100%;
  max-width: calc(var(--sp-container) + var(--sp-48));
  display: flex;
  flex-direction: column;
  gap: var(--sp-4);
  padding: var(--sp-4);

  .d2p-header,
  .d2p-header > div {
    max-width: calc(var(--sp-container) + var(--sp-48));
  }

  .d-color {
    color: #efba52;
  }

  .row {
    display: flex;
    gap: var(--sp-4);
  }

  .column {
    display: flex;
    flex-direction: column;

    &.sidebar {
      flex: 1;
      max-width: 320px;
      display: none;

      @container profile_container (min-width: 900px) {
        & {
          display: flex;
        }
      }
    }
    &.main {
      flex: 2;
    }
  }

  .stats {
    display: flex;
    gap: var(--sp-8);
    padding: var(--sp-4) var(--sp-6);
    background-color: var(--shade8);
    border: 1px solid var(--shade6);
    border-radius: var(--br-lg);
  }

  .matches {
    display: flex;
    flex-direction: column;
    gap: var(--sp-3);
  }
`;

const translations: { links: Record<string, Translation> } = {
  links: {
    overview: ["common:navigation.overview", "Overview"],
    guardians: ["destiny2:navigation.guardians", "Guardians"],
    weapons: ["common:navigation.weapons", "Weapons"],
    maps: ["common:maps", "Maps"],
  },
};

export const D2ProfileContainer: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { t } = useTranslation();
  const {
    parameters: [bungieId],
  } = useRoute();
  const {
    destiny2: { profiles },
  } = useSnapshot(readState);
  const profile = profiles[bungieId];
  return (
    <ProfileContainer>
      <PageHeader
        className="d2p-header"
        imageRound
        title={profile?.bungieName}
        image={`${appURLs.BUNGIE}${profile?.iconPath}`}
        links={[
          {
            text: t(...translations.links.overview),
            url: `/destiny2/profile/${bungieId}`,
          },
          {
            text: t(...translations.links.guardians),
            url: `/destiny2/profile/${bungieId}/guardians`,
          },
          {
            text: t(...translations.links.weapons),
            url: `/destiny2/profile/${bungieId}/weapons`,
          },
          {
            text: t(...translations.links.maps),
            url: `/destiny2/profile/${bungieId}/maps`,
          },
        ]}
      />
      {children}
    </ProfileContainer>
  );
};

const Profile = () => {
  const {
    parameters: [bungieId, tab],
  } = useRoute();
  const [characterFilter] = useQuery<string>("guardian");
  const {
    destiny2: { profiles },
  } = useSnapshot(readState);
  const profile = useMemo(
    () => filterErrState(profiles[bungieId]),
    [profiles, bungieId],
  );

  const characterIds = useMemo(
    () =>
      characterFilter
        ? [characterFilter]
        : (profile?.guardians.map((g) => g.characterId) ?? []),
    [characterFilter, profile?.guardians],
  );
  const [matchlistError, matchlist] = useMatchListFilter(
    bungieId,
    characterIds,
  );
  const matchlistSharedList = useMemo(
    () => matchlistError ?? matchlist ?? [],
    [matchlist, matchlistError],
  );

  const matchStats = useMatchAggregates(matchlist);
  const mapStats = useMapAggregates(profile, characterIds);
  const weaponStats = useWeaponAggregates(profile, characterIds);
  const guardianStats = useCharacterAggregates(profile);
  return (
    <D2ProfileContainer>
      {tab !== "guardians" && (
        <ProfileFilters bungieId={bungieId} guardians={profile?.guardians} />
      )}
      {tab === "guardians" ? (
        <Guardians profile={profile} guardianStats={guardianStats} />
      ) : tab === "weapons" ? (
        <Weapons weaponStats={weaponStats} />
      ) : tab === "maps" ? (
        <Maps profile={profile} mapStats={mapStats} />
      ) : (
        <SharedProfile>
          <ProfileColumn className="sidebar">
            <Sidebar
              profile={profile}
              matchStats={matchStats}
              mapStats={mapStats}
              weaponStats={weaponStats}
              guardianStats={guardianStats}
            />
          </ProfileColumn>
          <ProfileColumn className="main">
            <MatchHistoryHeader matchStats={matchStats} />
            <ProfileMatchlist>
              <MatchList matchList={matchlistSharedList}>
                {(matchlist ?? [])?.map(
                  ({ seasonId, id, match, playerMatch, characterId }) => (
                    <MatchTile
                      key={id}
                      id={`${seasonId}-${id}`}
                      match={match}
                      height={126}
                    >
                      {!!match && (
                        <MatchListRow
                          profile={profile}
                          matchlist={matchlist}
                          match={match}
                          playerMatch={playerMatch}
                          characterId={characterId}
                        />
                      )}
                    </MatchTile>
                  ),
                )}
              </MatchList>
            </ProfileMatchlist>
          </ProfileColumn>
        </SharedProfile>
      )}
    </D2ProfileContainer>
  );
};

export default Profile;

export function meta([bungieId]) {
  const name = readState.destiny2.profiles[bungieId]?.bungieName ?? bungieId;
  return {
    title: [
      "destiny2:meta.profile.title",
      "{{name}} Destiny 2 Stats & Performance Insights | Blitz",
      { name },
    ],
    description: [
      "destiny2:meta.profile.description",
      "View {{name}}'s Destiny 2 stats and performance breakdown on Blitz. Analyze PvP and PvE achievements, weapon loadouts, and combat efficiency in one place.",
      { name },
    ],
  };
}
