import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Card from "clutch/src/Card/Card.js";

import type { MatchAggStats } from "@/game-destiny2/utils/use-aggregate-stats.mjs";
import MatchHistoryHeader from "@/shared/MatchHistoryHeader.jsx";
import rangeBucket from "@/util/range-bucket.mjs";

const winRateBucket = rangeBucket([
  [0, "var(--perf-neg3)"],
  [46, "var(--perf-neg2)"],
  [48, "var(--perf-neg1)"],
  [50, "var(--perf-neutral)"],
  [51, "var(--perf-pos1)"],
  [53, "var(--perf-pos2)"],
  [55, "var(--perf-pos3)"],
]);

export const getWinRateColor = (rate) =>
  typeof rate === "number" ? winRateBucket[rate * 100] : "var(--shade3)";

export default memo(function MatchHistoryHeaderDestiny({
  matchStats,
}: {
  matchStats: MatchAggStats;
}) {
  // Hooks
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [statColumns, matchBarGraph] = useMemo(() => {
    if (!matchStats || !matchStats.gameCount)
      return [undefined, undefined] as const;
    const { wins, losses, gameCount, winRate, kda, averageHSPerc, score } =
      matchStats;
    const avgHS = averageHSPerc / 100;
    const statColumns = [
      {
        stat: t("common:stats.winsLosses", "{{wins}}W {{losses}}L", {
          wins,
          losses,
        }),
        description: t("val:lastNMatches", "Last {{matches}}", {
          matches: gameCount,
        }),
      },
      {
        stat: winRate.toLocaleString(language, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          style: "percent",
        }),
        description: t("common:stats.wr", "Winrate"),
        statColor: getWinRateColor(winRate),
      },
      {
        stat: kda ? kda : "- - -",
        description: t("common:stats.kda", "Avg. KDA"),
      },
      {
        stat: avgHS
          ? avgHS.toLocaleString(language, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              style: "percent",
            })
          : "- - -",
        description: t("common:stats.hsPercent", "Avg. HS%"),
        statColor: getWinRateColor(avgHS),
      },
      {
        stat: score.toLocaleString(),
        description: t("common:stats.avgScore", "Avg. Score"),
      },
    ];
    // Doesn't really make sense unless we know what weapon the user
    // used in each match. Removed for now.
    // if (weaponStats.length) {
    //   const weaponStat = weaponStatsByProfile[maxWeaponIdx];
    //   const topPickRate = calcRate(
    //     sanitizeNumber(weaponStat?.count),
    //     totalCount,
    //     2,
    //   );
    //   statColumns.push(
    //     {
    //       stat: weaponStat?.weapon.name ?? "",
    //       description: t("common:favorite.weapon", "Favorite Weapon"),
    //     },
    //     {
    //       stat: topPickRate.toLocaleString(undefined, {
    //         minimumFractionDigits: 0,
    //         maximumFractionDigits: 0,
    //         style: "percent",
    //       }),
    //       description: t("common:pick.rate", "Pick Rate"),
    //       statColor: getWinRateColor(topPickRate),
    //     },
    //   );
    // }
    return [
      statColumns,
      {
        won: wins,
        lost: losses,
      },
    ] as const;
  }, [matchStats, t, language]);

  if (matchStats && !statColumns) return null;

  if (!statColumns) return <Card loading style={{ height: "110px" }} />;

  return (
    <MatchHistoryHeader
      padding="var(--sp-5)"
      statColumns={statColumns}
      matchBarGraph={matchBarGraph}
    />
  );
});
