import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Button } from "clutch/src/Button/Button.js";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/constants.mjs";
import type { SubclassSlotType } from "@/game-destiny2/components/LoadoutBuilderComponents.jsx";
import {
  ArmorItemTarget,
  ArtifactItemTarget,
  SubclassItemTarget,
  SubclassModTarget,
  WeaponItemTarget,
} from "@/game-destiny2/components/LoadoutBuilderComponents.jsx";
import { CharacterAvatar as CharacterAvatarShared } from "@/game-destiny2/components/Profile/Components.jsx";
import {
  CLASS_BY_BE_ENUM,
  PRISMATIC_SUBCLASSES,
} from "@/game-destiny2/constants/class.mjs";
import { BUCKETS } from "@/game-destiny2/constants/inventory-buckets.mjs";
import { RACE } from "@/game-destiny2/constants/race.mjs";
import {
  ARMOR_STAT_MAP,
  ARMOR_STAT_ORDER,
  STATS,
} from "@/game-destiny2/constants/stats.mjs";
import type { CharacterLoadout } from "@/game-destiny2/models/bungie-character-loadout.mjs";
import type { Profile } from "@/game-destiny2/models/destiny2-bungie-profile.mjs";
import type {
  Armor,
  Artifact,
  Mod,
  Stat,
  Weapon,
} from "@/game-destiny2/models/model-wiki.mjs";
import { getStats } from "@/game-destiny2/utils/loadout-utils.mjs";
import type { CharacterStatsAgg } from "@/game-destiny2/utils/use-aggregate-stats.mjs";
import PowerIcon from "@/inline-assets/destiny2-power-icon.svg";
import calcKDA from "@/shared-fps/calc-kda.mjs";
import { displayRate } from "@/util/helpers.mjs";
import { useIsLoaded } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const CLASS_BG_FILTER = {
  [CLASS_BY_BE_ENUM.TITAN.beEnum]: "sepia(1) saturate(10) hue-rotate(330deg)",
  [CLASS_BY_BE_ENUM.WARLOCK.beEnum]: "sepia(1) saturate(10) hue-rotate(0deg)",
  [CLASS_BY_BE_ENUM.HUNTER.beEnum]: "none",
};

const Styled = {
  guardianCss: () => css`
    --card-max-w: 500px;
    --card-min-w: 380px;
    --px: var(--sp-6);
    --card-gap: var(--sp-4);
    --header-height: 110px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--card-gap);

    .layout {
      flex: 1 1 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(var(--card-min-w), 1fr));
      gap: var(--card-gap);
      justify-items: center;

      section {
        width: 100%;
        max-width: var(--card-max-w);
      }
    }

    .guardian-card {
      position: relative;
      overflow: hidden;

      dl,
      dt,
      dd,
      hr {
        margin: 0;
      }

      hr {
        width: 100%;
        border: none;
        border-top: 1px solid var(--card-inset-border);
      }

      .card-frame:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        aspect-ratio: 41 / 23;

        background: var(--bg-img);
        filter: var(--bg-filter);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        mask-image: linear-gradient(
          0deg,
          rgba(19, 22, 28, 0) 0%,
          var(--card-surface) 100%
        );
        opacity: 0.5;
      }

      .card-contents {
        display: flex;
        flex-direction: column;
        gap: var(--sp-4);

        & > :last-child {
          padding-bottom: var(--px);
        }
      }

      .class-icon {
        border-radius: var(--br);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--shade10-75);
        background-color: var(--class-bg);
        gap: var(--card-gap);
      }

      .character-name {
        display: flex;
        flex-direction: column;
      }

      .guardian-stats {
        height: var(--header-height);
        width: 100%;
        padding-inline: var(--px);

        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: var(--sp-2);
      }

      .char-section {
        display: flex;
        flex-direction: column;
        gap: var(--sp-4);
        padding-inline: var(--px);

        h3 {
          color: var(--shade3);
        }
      }

      .armor-stats ul {
        --max-w: 300px;
        max-width: var(--max-w);
        width: 100%;

        margin: auto;

        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: var(--sp-6);
        row-gap: var(--sp-4);

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .subclass-mods {
        height: min-content;
        display: grid;
        grid-template-columns: repeat(2, min-content);
        grid-template-rows: repeat(2, min-content);
        grid-auto-flow: column dense;
        column-gap: var(--sp-6);
        row-gap: var(--sp-5);
      }
    }
  `,
};

function Guardians({
  profile,
  guardianStats,
}: {
  profile: Profile;
  guardianStats: Record<string, CharacterStatsAgg>;
}) {
  const { t } = useTranslation();
  const {
    settings: { loggedInAccounts },
    destiny2: {
      characterLoadout: characterLoadoutMap,
      wiki: { weapons, armor, mods, stats: statMap, artifacts },
    },
  } = useSnapshot(readState);
  const isLoaded = useIsLoaded();
  const accountMap = loggedInAccounts.destiny2;
  const isOwnProfile = !!accountMap[profile?.bungieId];
  const guardians = profile?.guardians;
  const characterLoadout = characterLoadoutMap[profile?.bungieId] ?? {};
  const characters = characterLoadout.characters;

  if (!guardians || !isLoaded)
    return (
      <div className={Styled.guardianCss()}>
        <div className="layout">
          {new Array(3).fill(0).map((_, i) => (
            <Card key={i} loading style={{ height: "1400px" }} />
          ))}
        </div>
      </div>
    );

  return (
    <div className={Styled.guardianCss()}>
      {guardians && guardians.length < 3 && (
        <div className="layout">
          <Card
            title={
              !guardians.length
                ? t("destiny2:suggestions.noGuardians", "No Guardians")
                : t(
                    "destiny2:suggestions.missingGuardians",
                    "Missing Guardians?",
                  )
            }
          >
            <p className="type-caption--semi shade1">
              {t(
                "destiny2:suggestions.noGuardiansPlayMatch2",
                "Play some missions with the Blitz App open and come back here to see guardian stats!",
              )}
            </p>
          </Card>
        </div>
      )}
      <div className="layout">
        {guardians.slice(0, 3).map(({ characterId, class: beClass }) => {
          const stats = guardianStats?.[characterId];
          const character = characters?.[characterId];
          return (
            <Guardian
              key={characterId}
              characterId={characterId}
              isOwnProfile={isOwnProfile}
              beClass={beClass}
              stats={stats}
              character={character}
              weapons={weapons}
              armor={armor}
              mods={mods}
              statMap={statMap}
              artifacts={artifacts}
            />
          );
        })}
      </div>
    </div>
  );
}

const CharacterAvatar = ({
  beClass,
  character,
  light,
}: {
  beClass: string;
  character: CharacterLoadout;
  light?: number;
}) => {
  const {
    t,
    i18n: { language: lng },
  } = useTranslation();
  const { label } = CLASS_BY_BE_ENUM[beClass];

  return (
    <CharacterAvatarShared
      beClass={beClass}
      character={character}
      text={
        <div className="character-name">
          {character ? (
            <>
              <span className="flex align-center gap-1 d-color type-title--bold">
                {light?.toLocaleString(lng) ?? "----"}
                <PowerIcon />
              </span>
              <span className="type-caption--semi shade1">
                {t("destiny2:guardianName", "{{race}} {{class}}", {
                  race: t(...RACE[character.raceHash].label),
                  class: t(...label),
                })}
              </span>
            </>
          ) : (
            <span className="type-caption--semi shade1">{t(...label)}</span>
          )}
        </div>
      }
    />
  );
};

function Guardian({
  characterId,
  isOwnProfile,
  beClass,
  stats,
  character,
  weapons,
  artifacts,
  armor,
  mods,
  statMap,
}: {
  characterId: string;
  isOwnProfile: boolean;
  beClass: string;
  stats: CharacterStatsAgg;
  character: CharacterLoadout;
  weapons: Record<number, Weapon>;
  artifacts: Record<number, Artifact>;
  armor: Record<number, Armor>;
  mods: Record<number, Mod>;
  statMap: Record<number, Stat>;
}) {
  const {
    t,
    i18n: { language: lng },
  } = useTranslation();
  const equipment = character?.equipment;
  const equipmentStats = getStats(equipment);

  return (
    <section
      style={{
        "--bg-img": `url(${appURLs.CDN_PLAIN}/destiny2/destiny2-guardian-bg.png)`,
        "--bg-filter": CLASS_BG_FILTER[beClass],
      }}
    >
      <Card
        padding="0"
        as="div"
        classNameOuter="guardian-card"
        title={
          <CharacterAvatar
            beClass={beClass}
            character={character}
            light={equipmentStats[STATS.light]}
          />
        }
        headerControls={
          isOwnProfile ? (
            <Button
              bgColor="transparent"
              size="medium"
              href={`/destiny2/loadout-builder?characterId=${characterId}`}
            >
              {t("destiny2:loadoutBuilder.manageItems", "Manage Items")}
            </Button>
          ) : undefined
        }
      >
        <dl className="guardian-stats">
          {[
            {
              label: t("common:stats.winRate", "Win Rate"),
              value: displayRate(stats?.wins, stats?.gameCount),
            },
            {
              label: t("common:kda", "KDA"),
              value: calcKDA(stats?.kills, stats?.deaths, stats?.assists),
            },
            {
              label: t("common:stats.kills", "Kills"),
              value: stats?.kills.toLocaleString(lng),
            },
            {
              label: t("common:matches", "Matches"),
              value: stats?.gameCount.toLocaleString(lng),
            },
          ].map(({ label, value }, idx) => (
            <div key={idx} className="flex column">
              <dd className="type-subtitle--bold shade0">{value}</dd>
              <dt className="type-caption shade1">{label}</dt>
            </div>
          ))}
        </dl>
        {equipment && (
          <>
            <hr />
            <StatsSection equipmentStats={equipmentStats} />
            <hr />
            <SubclassSection
              character={character}
              mods={mods}
              statMap={statMap}
            />
            <hr />
            <WeaponsSection
              character={character}
              mods={mods}
              statMap={statMap}
              weapons={weapons}
            />
            <hr />
            <EquipmentSection character={character} artifacts={artifacts} />
            <hr />
            <ArmorSection
              character={character}
              mods={mods}
              statMap={statMap}
              armor={armor}
            />
          </>
        )}
      </Card>
    </section>
  );
}

function StatsSection({
  equipmentStats,
}: {
  equipmentStats: Record<number, number>;
}) {
  const { t } = useTranslation();

  return (
    <div className="char-section armor-stats">
      <h3 className="type-overline">{t("common:stats", "Stats")}</h3>
      <ul>
        {ARMOR_STAT_ORDER.map((hash) => {
          const { icon: Icon, label } = ARMOR_STAT_MAP[hash];
          return (
            <li key={hash}>
              <div className="type-caption flex align-center gap-2">
                <Icon />
                <span className="shade1">{t(...label)}</span>
              </div>
              <span className="type-callout--bold">
                {equipmentStats[hash] ?? "--"}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function SubclassSection({
  character,
  mods,
  statMap,
}: {
  character: CharacterLoadout;
  mods: Record<number, Mod>;
  statMap: Record<number, Stat>;
}) {
  const { t } = useTranslation();

  const eqptSubclass = character?.equipment[BUCKETS.subclass];
  const isPrismatic = PRISMATIC_SUBCLASSES.includes(eqptSubclass?.hash);
  return (
    <div className="char-section flex column gap-4">
      <h3 className="type-overline">
        {t("destiny2:class.subclass", "Subclass")}
      </h3>
      <SubclassItemTarget
        width={40}
        instance={eqptSubclass}
        modMap={mods}
        statMap={statMap}
      />
      <div className="subclass-mods">
        {(
          [
            isPrismatic && {
              label: t("destiny2:class.transcendence", "Transcendence"),
              target: "transcendence",
            },
            {
              label: t("destiny2:class.abilities", "Abilities"),
              target: "ability",
            },
            {
              label: t("destiny2:class.aspects", "Aspects"),
              target: "aspect",
            },
            {
              label: t("destiny2:class.fragments", "Fragments"),
              target: "fragment",
            },
          ] satisfies { label: string; target: SubclassSlotType }[]
        ).map(
          (slot) =>
            slot && (
              <div key={slot.target} className="flex column gap-4">
                <h3 className="type-overline">{slot.label}</h3>
                <div className="flex column gap-2">
                  <SubclassModTarget
                    modTarget={slot.target}
                    isPrismatic={isPrismatic}
                    instance={eqptSubclass}
                    modMap={mods}
                    statMap={statMap}
                  />
                </div>
              </div>
            ),
        )}
      </div>
    </div>
  );
}

function WeaponsSection({
  character,
  weapons,
  mods,
  statMap,
}: {
  character?: CharacterLoadout;
  weapons: Record<number, Weapon>;
  mods: Record<number, Mod>;
  statMap: Record<number, Stat>;
}) {
  const {
    t,
    i18n: { language: lng },
  } = useTranslation();

  const equipment = character?.equipment ?? {};
  return (
    <div className="char-section">
      <h3 className="type-overline">{t("common:weapons", "Weapons")}</h3>
      <ul className="flex column gap-2">
        {(["kinetic", "energy", "power"] as const).map((type) => {
          const instance = equipment[BUCKETS[type]];
          const lightLevel = instance?.primaryStatValue;
          return (
            <li key={type} className="flex gap-2 justify-between">
              <WeaponItemTarget
                width={40}
                instance={instance}
                type={type}
                weapons={weapons}
                modMap={mods}
                statMap={statMap}
                textType="type-body2-form--bold shade0"
                className="hide-light"
              />
              {lightLevel !== undefined && (
                <span className="flex align-center gap-1 shade0 type-caption--bold">
                  <PowerIcon width={12} height={12} />
                  {lightLevel.toLocaleString(lng)}
                </span>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function ArmorSection({
  character,
  armor,
  mods,
  statMap,
}: {
  character?: CharacterLoadout;
  armor: Record<number, Armor>;
  mods: Record<number, Mod>;
  statMap: Record<number, Stat>;
}) {
  const {
    t,
    i18n: { language: lng },
  } = useTranslation();

  const equipment = character?.equipment ?? {};
  return (
    <div className="char-section">
      <h3 className="type-overline">{t("common:armor", "Armor")}</h3>
      <ul className="flex column gap-2">
        {(["helmet", "gauntlets", "chest", "leg", "classItem"] as const).map(
          (type) => {
            const instance = equipment[BUCKETS[type]];
            const lightLevel = instance?.primaryStatValue;
            return (
              <li key={type} className="flex gap-2 justify-between">
                <ArmorItemTarget
                  width={40}
                  instance={equipment[BUCKETS[type]]}
                  type={type}
                  armor={armor}
                  modMap={mods}
                  statMap={statMap}
                  textType="type-body2-form--bold shade0"
                  className="hide-light"
                />
                {lightLevel !== undefined && (
                  <span className="flex align-center gap-1 shade0 type-caption--bold">
                    <PowerIcon width={12} height={12} />
                    {lightLevel.toLocaleString(lng)}
                  </span>
                )}
              </li>
            );
          },
        )}
      </ul>
    </div>
  );
}

function EquipmentSection({
  character,
  artifacts,
}: {
  character: CharacterLoadout;
  artifacts: Record<number, Artifact>;
}) {
  const {
    t,
    i18n: { language: lng },
  } = useTranslation();

  const equipment = character?.equipment ?? {};
  const artifact = equipment[BUCKETS.artifact];
  const artifactLevel = artifact?.primaryStatValue;
  return (
    <div className="char-section">
      <h3 className="type-overline">{t("common:equipment", "Equipment")}</h3>
      <ul className="flex column gap-2">
        <li className="flex gap-2 justify-between">
          <ArtifactItemTarget
            width={40}
            instance={artifact}
            artifacts={artifacts}
            textType="type-body2-form--bold shade0"
          />
          {artifactLevel !== undefined && (
            <span className="flex align-center gap-1 shade0 type-caption--bold">
              <PowerIcon width={12} height={12} />
              {artifactLevel.toLocaleString(lng, { signDisplay: "always" })}
            </span>
          )}
        </li>
      </ul>
    </div>
  );
}

export default Guardians;
