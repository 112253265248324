import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import { CharacterAvatar } from "@/game-destiny2/components/Profile/Components.jsx";
import { ACTIVITY_MODE } from "@/game-destiny2/constants/activity-modes.mjs";
import type { Profile } from "@/game-destiny2/models/destiny2-bungie-profile.mjs";
import type {
  Match,
  PlayerMatch,
} from "@/game-destiny2/models/destiny2-match.mjs";
import {
  getMatchAggregates,
  useMatchAggregates,
} from "@/game-destiny2/utils/use-aggregate-stats.mjs";
import type { FilteredMatchlist } from "@/game-destiny2/utils/use-match-list-filter.mjs";
import PowerIcon from "@/inline-assets/destiny2-power-icon.svg";
import MatchTileExpand from "@/shared/MatchTileExpand.jsx";
import { classNames } from "@/util/class-names.mjs";
import globals from "@/util/global-whitelist.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";
import { dataIsLoaded } from "@/util/wiki.mjs";

const Styled = {
  playerCss: () => css`
    display: grid;
    grid-template-columns: repeat(var(--cols, 1), minmax(50px, 100px));
    gap: var(--sp-0_5);

    .avatar-container,
    .avatar-spacer {
      overflow: hidden;
      gap: var(--sp-1);
    }

    .guardian-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `,
};

const MatchTileTabsEnum = {
  Scoreboard: "scoreboard",
  Statistics: "statistics",
  Hits: "hits",
};

const Tabs = {
  [MatchTileTabsEnum.Scoreboard]: () =>
    import("@/game-destiny2/components/MatchTileScoreboard.jsx"),
  [MatchTileTabsEnum.Statistics]: () =>
    import("@/game-destiny2/components/MatchTileStatistics.jsx"),
  [MatchTileTabsEnum.Hits]: () =>
    import("@/game-destiny2/components/MatchTileHits.jsx"),
};

export default function MatchListRow({
  match,
  matchlist = [],
  playerMatch,
  profile,
  characterId,
}: {
  match: Match;
  matchlist: FilteredMatchlist;
  playerMatch: PlayerMatch;
  profile: Profile;
  characterId: string;
}) {
  const {
    t,
    i18n: { language: lng },
  } = useTranslation();
  const {
    destiny2: {
      wiki: { activities },
    },
  } = useSnapshot(readState);
  const bungieId = profile?.bungieId;

  const last20Matchlist = useMemo(
    () => matchlist.filter(({ id }) => id !== match.id),
    [match.id, matchlist],
  );
  const matchStats = getMatchAggregates(playerMatch);
  const last20Aggregates = useMatchAggregates(last20Matchlist);

  if (!dataIsLoaded(activities)) return null;

  // TODO: BE is not giving enough details to determine win or not
  const isWin = !!playerMatch?.won;
  const { map, difficulty, activityModeEnum, iconUri } = activities[
    match.referenceId
  ] ?? {
    map: t("destiny2:unknownActivity", "Unknown Activity"),
  };
  const mode = ACTIVITY_MODE[activityModeEnum ?? match.mode];
  const titles = [
    {
      text: isWin
        ? t("common:stats.victory", "Victory")
        : t("common:stats.defeat", "Defeat"),
      bold: true,
      color: isWin ? "var(--turq)" : "var(--red)",
    },
    difficulty && {
      text: difficulty,
    },
    mode && {
      text: t(...mode.label),
    },
    {
      text: map,
    },
  ].filter(Boolean);

  const lightLevel = playerMatch.lightLevel;
  const iconSrc = iconUri ?? mode?.iconUri;
  const iconAlt = mode.label ?? [
    "destiny2:unknownActivity",
    "Unknown Activity",
  ];
  return (
    <MatchTileExpand
      isWin={isWin}
      image={{
        src: iconSrc ? `${appURLs.BUNGIE}${iconSrc}` : "",
        alt: t(...iconAlt),
      }}
      titles={titles}
      tabDefault={MatchTileTabsEnum.Scoreboard}
      tabsToElement={Tabs}
      tabsLabel={[
        {
          name: ["tft:matchtabs.scoreboard", "Scoreboard"],
          key: MatchTileTabsEnum.Scoreboard,
        },
        {
          name: ["common:navigation.statistics", "Statistics"],
          shortName: ["common:stats", "Stats"],
          key: MatchTileTabsEnum.Statistics,
        },
        {
          name: ["common:stats.kills", "Kills"],
          key: MatchTileTabsEnum.Hits,
        },
      ]}
      tabArgs={{
        match,
        playerMatch,
        last20Aggregates,
      }}
      stats={[
        {
          top: t("common:stats.kda", "{{ kda, number }} KDA", {
            kda: matchStats.kda,
          }),
          bottom: t("val:kdaWritteOut", "{{k}} / {{d}} / {{a}}", {
            k: matchStats.kills,
            d: matchStats.deaths,
            a: matchStats.assists,
          }),
        },
        {
          top: (
            <span className="flex align-center gap-1 d-color">
              {lightLevel?.toLocaleString(lng) ?? "----"}
              <PowerIcon width={10} height={10} />
            </span>
          ),
          bottom: t("destiny2:power", "Power"),
        },
        {
          top: (matchStats.averageHSPerc / 100).toLocaleString(lng, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            style: "percent",
          }),
          bottom: t("common:stats.hsPercent", "HS%"),
        },
        !!matchStats.score && {
          top: matchStats.score.toLocaleString(lng),
          bottom: t("common:stats.score", "Score"),
        },
      ].filter(Boolean)}
      urlWeb={`${globals.location.origin}/destiny2/match/${bungieId}/${match.seasonId}/${match.activityId}`}
      urlCanonical={`/destiny2/match/${bungieId}/${match.seasonId}/${match.activityId}`}
      aside={
        match.playerMatches.length && (
          <div
            className={Styled.playerCss()}
            style={{
              "--cols": Math.ceil(
                sanitizeNumber(match.playerMatches.length / 5) || 1,
              ),
            }}
          >
            {match.playerMatches.map(
              ({ guardian: { characterId: gId, class: beClass, name } }) => (
                <CharacterAvatar
                  width={15}
                  key={gId}
                  beClass={beClass}
                  text={
                    <span
                      {...classNames(
                        characterId === gId
                          ? "type-caption--semi shade0"
                          : "type-caption shade2",
                        "guardian-name",
                      )}
                    >
                      {name}
                    </span>
                  }
                />
              ),
            )}
          </div>
        )
      }
      matchDate={new Date(match.startedAt)}
    />
  );
}
