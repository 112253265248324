import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { appURLs } from "@/app/app-urls.mjs";
import { CLASS_BY_BE_ENUM } from "@/game-destiny2/constants/class.mjs";
import type { CharacterLoadout } from "@/game-destiny2/models/bungie-character-loadout.mjs";
import { Avatar } from "@/shared/Avatar.jsx";

const ClassIcon = ({
  width = "100%",
  height = "100%",
  icon: Icon,
  color,
}: {
  width?: number | string;
  height?: number | string;
  icon: SVGComponent;
  color: string;
}) => (
  <div
    className="flex align-center justify-center br"
    style={{ backgroundColor: color, width, height }}
  >
    <Icon width="80%" height="80%" />
  </div>
);

const CharacterAvatarImpl = ({
  beClass,
  character,
  text,
  width = 48,
}: {
  beClass: string;
  character?: CharacterLoadout;
  text?: React.ReactNode;
  width?: number;
}) => {
  const { t } = useTranslation();
  const { icon: Icon, color, label } = CLASS_BY_BE_ENUM[beClass];

  return (
    <Avatar
      variant="square"
      avatar={
        character ? (
          `${appURLs.BUNGIE}${character.emblemPath}`
        ) : (
          <ClassIcon icon={Icon} color={color} />
        )
      }
      alt={t(...label)}
      dataMask={character ? "square" : undefined}
      dataIcon={character ? <ClassIcon icon={Icon} color={color} /> : undefined}
      avatarWidth={width}
      title={text}
    />
  );
};

export const CharacterAvatar = memo(CharacterAvatarImpl);
